import { Injectable } from '@angular/core';
import { Subject, Observable, of, merge, fromEvent, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title, Meta } from '@angular/platform-browser';
import { mapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { identify, Identify, reset, setUserId, track} from '@amplitude/analytics-browser';
import { ContactUsComponent } from '../shared/contact-us/contact-us.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // height
  heightData = [
    {
      id: 137,
      value: '4ft 6in – 137cm or less'
    },
    {
      id: 138,
      value: '4ft 6.5in – 138cm'
    },
    {
      id: 140,
      value: '4ft 7in – 140cm'
    },
    {
      id: 141,
      value: '4ft 7.5in – 141cm'
    },
    {
      id: 142,
      value: '4ft 8in – 142cm'
    },
    {
      id: 144,
      value: '4ft 8.5in – 144cm'
    },
    {
      id: 145,
      value: '4ft 9in – 145cm'
    },
    {
      id: 146,
      value: '4ft 9.5in – 146cm'
    },
    {
      id: 147,
      value: '4ft 10in – 147cm'
    },
    {
      id: 149,
      value: '4ft 10.5in – 149cm'
    },
    {
      id: 150,
      value: '4ft 11in – 150cm'
    },
    {
      id: 151,
      value: '4ft 11.5in – 151cm'
    },
    {
      id: 152,
      value: '5ft – 152cm'
    },
    {
      id: 154,
      value: '5ft 0.5in – 154cm'
    },
    {
      id: 155,
      value: '5ft 1in – 155cm'
    },
    {
      id: 156,
      value: '5ft 1.5in – 156cm'
    },
    {
      id: 157,
      value: '5ft 2in – 157cm'
    },
    {
      id: 159,
      value: '5ft 2.5in – 159cm'
    },
    {
      id: 160,
      value: '5ft 3in – 160cm'
    },
    {
      id: 161,
      value: '5ft 3.5in – 161cm'
    },
    {
      id: 163,
      value: '5ft 4in – 163cm'
    },
    {
      id: 164,
      value: '5ft 4.5in – 164cm'
    },
    {
      id: 165,
      value: '5ft 5in – 165cm'
    },
    {
      id: 166,
      value: '5ft 5.5in – 166cm'
    },
    {
      id: 168,
      value: '5ft 6in – 168cm'
    },
    {
      id: 169,
      value: '5ft 6.5in – 169cm'
    },
    {
      id: 170,
      value: '5ft 7in – 170cm'
    },
    {
      id: 171,
      value: '5ft 7.5in – 171cm'
    },
    {
      id: 173,
      value: '5ft 8in – 173cm'
    },
    {
      id: 174,
      value: '5ft 8.5in – 174cm'
    },
    {
      id: 175,
      value: '5ft 9in – 175cm'
    },
    {
      id: 177,
      value: '5ft 9.5in – 177cm'
    },
    {
      id: 178,
      value: '5ft 10in – 178cm'
    },
    {
      id: 179,
      value: '5ft 10.5in – 179cm'
    },
    {
      id: 180,
      value: '5ft 11in – 180cm'
    },
    {
      id: 182,
      value: '5ft 11.5in – 182cm'
    },
    {
      id: 183,
      value: '6ft – 183cm'
    },
    {
      id: 184,
      value: '6ft 0.5in – 184cm'
    },
    {
      id: 185,
      value: '6ft 1in – 185cm'
    },
    {
      id: 187,
      value: '6ft 1.5in – 187cm'
    },
    {
      id: 188,
      value: '6ft 2in – 188cm'
    },
    {
      id: 189,
      value: '6ft 2.5in – 189cm'
    },
    {
      id: 191,
      value: '6ft 3in – 191cm'
    },
    {
      id: 192,
      value: '6ft 3.5in – 192cm'
    },
    {
      id: 193,
      value: '6ft 4in – 193cm'
    },
    {
      id: 194,
      value: '6ft 4.5in – 194cm'
    },
    {
      id: 196,
      value: '6ft 5in – 196cm'
    },
    {
      id: 197,
      value: '6ft 5.5in – 197cm'
    },
    {
      id: 198,
      value: '6ft 6in – 198cm'
    },
    {
      id: 199,
      value: '6ft 6.5in – 199cm'
    },
    {
      id: 201,
      value: '6ft 7in – 201cm'
    },
    {
      id: 202,
      value: '6ft 7.5in – 202cm'
    },
    {
      id: 203,
      value: '6ft 8in – 203cm'
    },
    {
      id: 204,
      value: '6ft 8.5in – 204cm'
    },
    {
      id: 206,
      value: '6ft 9in – 206cm'
    },
    {
      id: 207,
      value: '6ft 9.5in – 207cm'
    },
    {
      id: 208,
      value: '6ft 10in – 208cm'
    },
    {
      id: 210,
      value: '6ft 10.5in – 210cm'
    },
    {
      id: 211,
      value: '6ft 11in – 211cm'
    },
    {
      id: 212,
      value: '6ft 11.5in – 212cm'
    },
    {
      id: 213,
      value: '7ft – 213cm or more'
    }
  ];
  amplitudeData;
  countryCodeData;
  customFbEventsData;
  assistedSignUp = false;
  createProfileHeaderHeading = new Subject<string>();
  createProfileHeaderProgressValue = new Subject<number>();
  accountHibernationRequested = new Subject<boolean>();
  updateBrowseData = new Subject<number>();
  unreadMsg = new Subject<any>();
  // ************************************************
  // check later
  // chatSecureDial = false;
  // chatViewPhoneNumber = false;
  // inCompleteProfileSecureDialDialog = new Subject();
  // inCompleteProfileViewPhoneNoDialog = new Subject();
  // ************************************************
  CreateProfileImageData: any = {
    imageData: null,
    hashData: null,
    slotNumber: null,
    editable: false,
    description: null,
    privacy: null,
    isProfile: null,
    declined: null,
    declinereason: null
  };
  countBrowseHeaderClicked = 1;
  clickPreferenceHighlighter = false;
  sharedBrowseDisplayDataArray = [];
  prefsTempContainer = new Subject<any>();
  preferencesData = [
    null, // for age 0
    null, // for martial 1
    null, // for location 2
    null, // for already has child 3
  ];
  // currentActivatePage refers to which page is currently active
  //  1 -> browse
  //  2 -> explore
  //  3 -> preference
  //  4 -> Notification
  //  5 -> my Account
  currentActivatePage = new Subject<number>();
  photoGallaryDataCurrentIndex = 0;
  screeningBrowseData = [];
  // browse works
  browsedData: any = [];
  totalBrowseCount = 0;
  photoGallaryData = [];
  editedImageData;
  totalImageData;
  browseScreenHeight = 420;
  exploreSeeAllScreenHeight = 420;
  currentBrowseProfileIndex = 0;
  forChatActive = false;
  forDiwaliOffer = false;
  forOneWeekSuccessModel = false;
  forOneWeekShowModel = false;
  oneWeekFreePlanBanner = false;
  oneWeekFreePlanscreeningBanner = false;
  browseLimitCount = 1;
  exploreLoadFirstTime = 1;
  browseLoadFirstTime = 1;
  // currentExploreProfileIndex = 0;
  browsedProfileCount = new Subject<boolean>();
  browseActionButtonPress = 0;
  // notification work
  getAllNotificationData = new Subject<any>();
  hasOwnProfileUpdatedResponse = false;
  // Explore shortlist
  exploreDataArray = {
    featuredMatch: [],
    recentlyJoined: [],
    recentlyActive: [],
    viewedMe: [],
    profileMayLike: [],
    shortlisted: [],
    viewedByMe: [],
    ingored: []
  };
  completeSectionOwnProfileData;
  completeSectionOtherProfileData;
  // inbox works
  unreadMessageCount = new Subject<any>();

  // own data
  ownProfileData = new Subject<any>();
  //block chatmessageUpdate
  blockChatmsgUpdate = new Subject<any>();
  blockRecentlyMsgUpdate = new Subject<any>();
  blockedAtChatmsg = false;
  ownProfileStoreddata;

  // add chat box
  addChatBox = new Subject<number>();

  // hide chat box
  hideChatSection = new Subject<any>();

  // remove chat box
  removeChatBox = new Subject<number>();

  // chat box array
  chatBoxCounter: number[] = [];
  // 90 days timestamp
  nintyDayTimestamp = 7776000000;
  emailBanner = false;
  planCountDownTimer ;
  interestInYouCount = new Subject<number>();
  // to check internet connection
  online$: Observable<boolean>;
  comingForContactUs = false;
  checkComingFromUploadDiplomaPhotoId = false;


  constructor(private md: MatDialog,private router: Router, private snackbar: MatSnackBar, private localservice: LocalStorageService, private apiservice: ApiService, private title: Title, private meta: Meta, private activatedroute: ActivatedRoute) {
    // console.log(this.getRouteForRegister(26));
  }


  boxBlurCanvasRGBA(id, top_x, top_y, width, height, radius, iterations) {
    let mul_table = [1, 57, 41, 21, 203, 34, 97, 73, 227, 91, 149, 62, 105, 45, 39, 137, 241, 107, 3, 173, 39, 71, 65, 238, 219, 101, 187, 87, 81, 151, 141, 133, 249, 117, 221, 209, 197, 187, 177, 169, 5, 153, 73, 139, 133, 127, 243, 233, 223, 107, 103, 99, 191, 23, 177, 171, 165, 159, 77, 149, 9, 139, 135, 131, 253, 245, 119, 231, 224, 109, 211, 103, 25, 195, 189, 23, 45, 175, 171, 83, 81, 79, 155, 151, 147, 9, 141, 137, 67, 131, 129, 251, 123, 30, 235, 115, 113, 221, 217, 53, 13, 51, 50, 49, 193, 189, 185, 91, 179, 175, 43, 169, 83, 163, 5, 79, 155, 19, 75, 147, 145, 143, 35, 69, 17, 67, 33, 65, 255, 251, 247, 243, 239, 59, 29, 229, 113, 111, 219, 27, 213, 105, 207, 51, 201, 199, 49, 193, 191, 47, 93, 183, 181, 179, 11, 87, 43, 85, 167, 165, 163, 161, 159, 157, 155, 77, 19, 75, 37, 73, 145, 143, 141, 35, 138, 137, 135, 67, 33, 131, 129, 255, 63, 250, 247, 61, 121, 239, 237, 117, 29, 229, 227, 225, 111, 55, 109, 216, 213, 211, 209, 207, 205, 203, 201, 199, 197, 195, 193, 48, 190, 47, 93, 185, 183, 181, 179, 178, 176, 175, 173, 171, 85, 21, 167, 165, 41, 163, 161, 5, 79, 157, 78, 154, 153, 19, 75, 149, 74, 147, 73, 144, 143, 71, 141, 140, 139, 137, 17, 135, 134, 133, 66, 131, 65, 129, 1];


    let shg_table = [0, 9, 10, 10, 14, 12, 14, 14, 16, 15, 16, 15, 16, 15, 15, 17, 18, 17, 12, 18, 16, 17, 17, 19, 19, 18, 19, 18, 18, 19, 19, 19, 20, 19, 20, 20, 20, 20, 20, 20, 15, 20, 19, 20, 20, 20, 21, 21, 21, 20, 20, 20, 21, 18, 21, 21, 21, 21, 20, 21, 17, 21, 21, 21, 22, 22, 21, 22, 22, 21, 22, 21, 19, 22, 22, 19, 20, 22, 22, 21, 21, 21, 22, 22, 22, 18, 22, 22, 21, 22, 22, 23, 22, 20, 23, 22, 22, 23, 23, 21, 19, 21, 21, 21, 23, 23, 23, 22, 23, 23, 21, 23, 22, 23, 18, 22, 23, 20, 22, 23, 23, 23, 21, 22, 20, 22, 21, 22, 24, 24, 24, 24, 24, 22, 21, 24, 23, 23, 24, 21, 24, 23, 24, 22, 24, 24, 22, 24, 24, 22, 23, 24, 24, 24, 20, 23, 22, 23, 24, 24, 24, 24, 24, 24, 24, 23, 21, 23, 22, 23, 24, 24, 24, 22, 24, 24, 24, 23, 22, 24, 24, 25, 23, 25, 25, 23, 24, 25, 25, 24, 22, 25, 25, 25, 24, 23, 24, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 23, 25, 23, 24, 25, 25, 25, 25, 25, 25, 25, 25, 25, 24, 22, 25, 25, 23, 25, 25, 20, 24, 25, 24, 25, 25, 22, 24, 25, 24, 25, 24, 25, 25, 24, 25, 25, 25, 25, 22, 25, 25, 25, 24, 25, 24, 25, 18];

    if (isNaN(radius) || radius < 1) { return; }

    radius |= 0;

    if (isNaN(iterations)) { iterations = 1; }
    iterations |= 0;
    if (iterations > 3) { iterations = 3; }
    if (iterations < 1) { iterations = 1; }

    const canvas = document.getElementById(id) as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(0, 0, width, height);
    /* try {
         try {
             imageData = context.getImageData(top_x, top_y, width, height);
         } catch (e) {

             // NOTE: this part is supposedly only needed if you want to work with local files
             // so it might be okay to remove the whole try/catch block and just use
             // imageData = context.getImageData( top_x, top_y, width, height );
             try {
                 netscape.security.PrivilegeManager.enablePrivilege("UniversalBrowserRead");
                 imageData = context.getImageData(top_x, top_y, width, height);
             } catch (e) {
                 alert("Cannot access local image");
                 throw new Error("unable to access local image data: " + e);
                 return;
             }
         }
     } catch (e) {
         alert("Cannot access image");
         throw new Error("unable to access image data: " + e);
         return;
     }*/

    const pixels = imageData.data;

    let rsum, gsum, bsum, asum, x, y, i, p, p1, p2, yp, yi, yw, idx, pa;
    const wm = width - 1;
    const hm = height - 1;
    const wh = width * height;
    const rad1 = radius + 1;

    const mul_sum = mul_table[radius];
    const shg_sum = shg_table[radius];

    const r = [];
    const g = [];
    const b = [];
    const a = [];

    const vmin = [];
    const vmax = [];

    while (iterations-- > 0) {
      yw = yi = 0;

      for (y = 0; y < height; y++) {
        rsum = pixels[yw] * rad1;
        gsum = pixels[yw + 1] * rad1;
        bsum = pixels[yw + 2] * rad1;
        asum = pixels[yw + 3] * rad1;


        for (i = 1; i <= radius; i++) {
          p = yw + (((i > wm ? wm : i)) << 2);
          rsum += pixels[p++];
          gsum += pixels[p++];
          bsum += pixels[p++];
          asum += pixels[p];
        }

        for (x = 0; x < width; x++) {
          r[yi] = rsum;
          g[yi] = gsum;
          b[yi] = bsum;
          a[yi] = asum;

          if (y === 0) {
            vmin[x] = ((p = x + rad1) < wm ? p : wm) << 2;
            vmax[x] = ((p = x - radius) > 0 ? p << 2 : 0);
          }

          p1 = yw + vmin[x];
          p2 = yw + vmax[x];

          rsum += pixels[p1++] - pixels[p2++];
          gsum += pixels[p1++] - pixels[p2++];
          bsum += pixels[p1++] - pixels[p2++];
          asum += pixels[p1] - pixels[p2];

          yi++;
        }
        yw += (width << 2);
      }

      for (x = 0; x < width; x++) {
        yp = x;
        rsum = r[yp] * rad1;
        gsum = g[yp] * rad1;
        bsum = b[yp] * rad1;
        asum = a[yp] * rad1;

        for (i = 1; i <= radius; i++) {
          yp += (i > hm ? 0 : width);
          rsum += r[yp];
          gsum += g[yp];
          bsum += b[yp];
          asum += a[yp];
        }

        yi = x << 2;
        for (y = 0; y < height; y++) {

          pixels[yi + 3] = pa = (asum * mul_sum) >>> shg_sum;
          if (pa > 0) {
            pa = 255 / pa;
            pixels[yi] = ((rsum * mul_sum) >>> shg_sum) * pa;
            pixels[yi + 1] = ((gsum * mul_sum) >>> shg_sum) * pa;
            pixels[yi + 2] = ((bsum * mul_sum) >>> shg_sum) * pa;
          } else {
            pixels[yi] = pixels[yi + 1] = pixels[yi + 2] = 0;
          }
          if (x === 0) {
            vmin[y] = ((p = y + rad1) < hm ? p : hm) * width;
            vmax[y] = ((p = y - radius) > 0 ? p * width : 0);
          }

          p1 = x + vmin[y];
          p2 = x + vmax[y];

          rsum += r[p1] - r[p2];
          gsum += g[p1] - g[p2];
          bsum += b[p1] - b[p2];
          asum += a[p1] - a[p2];

          yi += width << 2;
        }
      }
    }

    context.putImageData(imageData, top_x, top_y);

  }

  updateBlockChatMessage(skip,limit,type){
    this.apiservice.getInboxData({ skip: skip, limit, type }).subscribe(result => {
    this.blockChatmsgUpdate.next(result);
    }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
      //  this.localservice.removeToken('user');
        this.clearlocalstorageData();
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  updateRecentlyMessage(skip,limit){
    this.apiservice.getrecentlyactiveuser({ skip: skip, limit }).subscribe(result => {
      this.blockRecentlyMsgUpdate.next(result);
    }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
      //  this.localservice.removeToken('user');
        this.clearlocalstorageData();
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
    
   
  }


  updateOwnProfile() {
    //console.log("update own profile");
    this.apiservice.getownprofile().subscribe(res => {
      this.ownProfileData.next(res.userdata[0]);
      this.ownProfileStoreddata = res.userdata[0];
      const localdata = this.localservice.getFromLocal('user');

       // for plans and packs
       if (this.ownProfileStoreddata.plansNpacks.assisted) {
        localdata.accounttype = 'ACP';
      } else if (this.ownProfileStoreddata.plansNpacks.express) {
        localdata.accounttype = 'ECP';
      } else if (this.ownProfileStoreddata.plansNpacks.quick) {
        localdata.accounttype = 'QCP';
      } else {
        localdata.accounttype = 'Free Forever members';
      }
      this.localservice.setToLocal('user', localdata);
    }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
      //  this.localservice.removeToken('user');
        this.clearlocalstorageData();
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  getAvatarPhoto() {
    const localData = this.localservice.getFromLocal('user');
    if (localData.ismale) {
      return '/assets/images/static/Female-avatar-600x600.jpg';
    } else {
      return '/assets/images/static/male-avatar-600x600.jpg';
    }
  }
  getThumbAvatarPhoto() {
    const localData = this.localservice.getFromLocal('user');
    if (localData.ismale) {
      return '/assets/images/static/Female-avatar-200x200.jpg';
    } else {
      return '/assets/images/static/male-avatar-200x200.jpg';
    }
  }

  getOwnAvatarPhoto() {
    const localData = this.localservice.getFromLocal('user');
    if (localData.ismale) {
      return '/assets/images/static/male-avatar-200x200.jpg';
    } else {
      return '/assets/images/static/Female-avatar-200x200.jpg';
    }
  }
  // get user current status onboarding, profile screening or postlogin
  userType() {
    const localdata = this.localservice.getFromLocal('user');
    try {
      if (localdata) {
        if (localdata.issignuppending) {
          return 'signUpPending';
        } else {
          if (localdata.showscreeningpage) {
            return 'screening';
          } else {
            return 'signUpDone';
          }

        }
      } else {
        return 'static'
      }
    } catch (err) {
      // console.log('error in getting local data ' + err);
    }
  }
  // if server error occurs
  gettingServerError() {
    const localData = this.localservice.getFromLocal('user');
    this.localservice.setToLocal('errorstatus', 'true')
    if (localData.issignuppending) {
      this.router.navigate(['/register/oops'], { skipLocationChange: true });
    } else {
      this.router.navigate(['/user/oops'], { skipLocationChange: true });
    }
  }

  //CHECK CONTACT-US FROM ANYWHERE
 
  sharedcontactUsDialog(){
      const contactUSdialog = this.md.open(ContactUsComponent, {
        autoFocus: false,
        maxWidth: '50vw',
        maxHeight: '90vh',
        data: { from: 'Onboarding' }
      });
      contactUSdialog.afterClosed().subscribe(res => {
        if(res){
          this.comingForContactUs = false;
        }
      });
  }
   

  // open snackbar
  openSnackbarMessageWithTime(message, time) {
    this.snackbar.open(message, '', {
      duration: time
    });
  }
  // set photo gallary of user
  setPhotoGallarydata(data) {
    this.photoGallaryData = data;
  }
  // get photo gallary of user
  getPhotoGallaryData() {
    return this.photoGallaryData;
  }
  // route navigation for edit profile
  navigateToEditProfile(params) {
    this.router.navigate(['/user/edit-profile/' + params]);
  }
  // return heightdata
  getHeightData() {
    return this.heightData;
  }
  // return height data on the basis of height in cm
  getConvertedHeight(data) {
    const heightIncm = this.heightData.find(e => e.id === data);
    return heightIncm.value;
  }

  jumpScrollTOBottom() {
    const b = document.getElementById('bottom');
    b.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  getOwnProfileImage() {
    const imageArray = this.ownProfileStoreddata?.images && this.ownProfileStoreddata?.images.imagedata ? this.ownProfileStoreddata?.images.imagedata : null;
    const imagePath = (imageArray && this.ownProfileStoreddata.profileimage) ? imageArray.filter(e => e.nodeid === this.ownProfileStoreddata.profileimage) : null;
    if (imagePath) {
      return imagePath[0].imageurl;
    } else {
      return this.getOwnAvatarPhoto();
    }
  }

  getRouteForRegister(currentQuesNumber) {
    if (typeof currentQuesNumber === 'number') {
      if (currentQuesNumber === 0) {
        return '/register/user-account-created';
      } else if (currentQuesNumber === 1) {
       return '/register/phone-verification-done';
      } else if ((currentQuesNumber > 1) && (currentQuesNumber <= 5)) {
        return '/register/basics';
      } else if ((currentQuesNumber > 5) && (currentQuesNumber <= 9)) {
        return '/register/appearance';
      } else if ((currentQuesNumber > 9) && (currentQuesNumber <= 15)) {
        return '/register/education';
      } else if ((currentQuesNumber > 15) && (currentQuesNumber <= 19)) {
        return '/register/sociocul';
      } else if ((currentQuesNumber > 19) && (currentQuesNumber <= 26)) {
        return '/register/lifestyle';
      } else if ((currentQuesNumber > 26) && (currentQuesNumber <= 36)) {
        return '/register/about-family';
      } else if (currentQuesNumber === 37) {
        return '/register/summary/own/description';
      } else if(currentQuesNumber === 38) {
        return '/register/add-prefs';
      } else if (currentQuesNumber === 39) {
        return '/register/summary/partner/description';
      } else if (currentQuesNumber === 40) {
        return '/register/add-photos';
      } else if (currentQuesNumber === 41) {
        return '/register/video-verification/overview';
      } else if (currentQuesNumber === 42) {
        const localData = this.localservice.getFromLocal('user');
        if (localData && localData.isphoneverified) {
          delete localData.issignuppending;
          localData.showscreeningpage = true;
          this.localservice.setToLocal('user', localData);
          return '/user/profile-screening/pending';
        } else {
          return '/register/verify-phone-number';
        }
      }
    }
  }

  // logOut() {
  //   if (this.localservice.getFromLocal('ziggeoVideoToken')) { // Its better safety ziggeovideotoken remove from localstorage.
  //     this.localservice.removeToken('ziggeoVideoToken');
  //   }
  //   localStorage.clear();
  //   window.location.reload();
  // }
  // getOnlineStatus(accesstime: number) {
  //   if (accesstime) {
  //     const currentTimeStamp = new Date().getTime();
  //     if (currentTimeStamp - accesstime < 300000) {
  //       return 'Online';
  //     } else if (currentTimeStamp - accesstime < this.nintyDayTimestamp) {
  //       const accessDate = new Date(accesstime);
  //       const twentyFourHourTimeStamp = 86400000;
  //       const currentMinusAccess = currentTimeStamp - accesstime;
  //       if (currentMinusAccess < twentyFourHourTimeStamp) {
  //         return 'Last online ' + this.formatDate(accessDate, 24);
  //       } else {
  //         return 'Last online ' + this.formatDate(accessDate, 90);
  //       }
  //     } else {
  //       return null;
  //     }
  //   }
  // }

  // recently active section

  getOnlineStatus(accesstime: number) {
    if (accesstime) {
      const currentTimeStamp = new Date().getTime();
      const accessDate = new Date(accesstime);
      const twentyFourHourTimeStamp = 86400000;
      const currentMinusAccess = currentTimeStamp - accesstime;
      if(currentMinusAccess < (twentyFourHourTimeStamp * 3)) {
        if (currentTimeStamp - accesstime < 300000) {
          return 'Online';
        } else {
            return 'Recently active'
        }
      }
       else {
        return null;
      }
    }
  }




  // return according to within 24 hours, same year, not same year
  showNotificationTime(accesstime: number) {
    try {
      const requestTime = new Date(accesstime);
      const currentTimeStamp = new Date().getTime();
      const currentMinusAccess = currentTimeStamp - accesstime;
      const twentyFourHourTimeStamp = 86400000;
      const fourtyEightHourTimeStamp = 172800000;
      if (currentMinusAccess < twentyFourHourTimeStamp) {
       // return 'day';  within 24 hour
       return "Today"; //within 24 hour
      } else if(currentMinusAccess > twentyFourHourTimeStamp && currentMinusAccess < fourtyEightHourTimeStamp){
        return "Yesterday";
      }else if (new Date().getFullYear() === requestTime.getFullYear()) {
        return 'same year'; // in current year
      } else {
        return 'not same year'; // not in current year
      }
    } catch (error) {
      // console.log(error);
    }
  }
  formatDate(date, val) {
    if (val === 24) {
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    } else {
      return date.toLocaleString('en-US', { month: 'short', day: 'numeric' });
    }
  }
  updateAllNotification() {
    const errorStatus = this.localservice.getFromLocal('errorstatus');
    this.apiservice.getAllNotificationUpdate().subscribe(res => {
      if (res) {
        if (errorStatus && errorStatus === 'true') {
          this.localservice.removeToken('errorstatus');
        //  window.location.reload();
        }
        this.getAllNotificationData.next(res);
      }
      // this.getAllNotificationData.next(res.body);
    }, (err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 400) {
      //  this.localservice.removeToken('user');
        this.clearlocalstorageData();
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  // call explore shortlist api
  updateExploreShortListApi(){
    this.apiservice.getExploreData({ skip : 0, type : 'other', relType : 'shortlisted', limit : 12}).subscribe(res => {
      this.exploreDataArray.shortlisted = res.result.userdata.slice(0, 6);
    }, (err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 400) {
      //  this.localservice.removeToken('user');
        this.clearlocalstorageData();
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  updateExploreViewedByMe(){
    this.apiservice.getExploreData({ skip : 0, type : 'other', relType : 'viewedOut', limit : 12}).subscribe(res => {
      this.exploreDataArray.viewedByMe = res.result.userdata.slice(0, 6);
    }, (err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 400) {
      //  this.localservice.removeToken('user');
        this.clearlocalstorageData();
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  updateExploreIgnoredProfile(){
    this.apiservice.getExploreData({ skip : 0, type : 'other', relType : 'blocked', limit : 12}).subscribe(res => {
      this.exploreDataArray.ingored = res.result.userdata.slice(0, 6);
    }, (err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 400) {
       // this.localservice.removeToken('user');
       this.clearlocalstorageData();
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  setTitle(value) {
    //  console.log(value);
    this.title.setTitle(value);
    this.meta.updateTag({ itemprop: 'name', content: value }, 'itemprop="name"');
    this.meta.updateTag({ property: 'og:title', content: value }, 'property="og.title"');
    this.meta.updateTag({ name: 'twitter:title', content: value }, 'name="twitter.title"');
  }
  setMetaDescription(value) {
    // console.log(value);
    this.meta.updateTag({ itemprop: 'description', content: value }, 'itemprop="description"');
    this.meta.updateTag({ property: 'og:description', content: value }, 'property="og.description"');
    this.meta.updateTag({ name: 'twitter:description', content: value }, 'name="twitter.description"');
  }
  setMetaImage(value) {
    this.meta.updateTag({ itemprop: 'image', content: value }, 'itemprop="image"');
    this.meta.updateTag({ property: 'og:image', content: value }, 'property="og.image"');
    this.meta.updateTag({ name: 'twitter:image', content: value }, 'name="twitter.image"');
  }
  setMetaUrl(value) {
    this.meta.updateTag({ name: 'url', content: value });
    this.meta.updateTag({ property: 'og:url', content: value });
    this.meta.updateTag({ name: 'twitter:url', content: value });
  }

  // track plans and packs of user
  trackPlanPack(from: string, otherId?: number) {
    let params: any;
    if (otherId) {
      params = {
        CTA: from,
        eventcategory: 'PlansPacks',
        otherid: otherId,
        from: 'Desktop Web'
      };
    } else {
      params = {
        CTA: from,
        eventcategory: 'PlansPacks',
        from: 'Desktop Web'
      };
    }
    // console.log(params);
    // call api
    this.apiservice.clickcaptur(params).subscribe(result => {
      if (result) {
        // console.log('true');
      }
    });

  }

       // amplitude and clevertap events
  logAnalyticsEvent(eventname, attributes = null) {
       //console.log(eventname);
      const windowTemp: any = window;
      const eventProperties = {
        url: window.location.href
      };
      if (environment.production) {
      track(eventname, { ...eventProperties, ...attributes });
      // windowTemp.clevertap.event.push(eventname, { ...eventProperties, ...attributes });
    }
  }
  identifyAnalyticsEvent(key, value){
    if (environment.production) {
      identify(new Identify().set(key, value));
    }
    // const identifyObj = new Identify()
    //       identifyObj.set(key, value);
    //       identify(identifyObj);
  }
  setUserIdAnalyticsEvent(username){
    if (environment.production) {
      setUserId(username);
    }
  }
  resetAnalyticsEvent(){
    if (environment.production) {
      setUserId(null);
      reset();
    }
  }

 // fb events
 logcustomFbEvents(eventname){
  let fbLocalData = this.localservice.getFromLocal('fbUniqueEvents');
  if(!fbLocalData){
    const data:any = {}
    data.events =[];
    fbLocalData = data;
   this.localservice.setToLocal('fbUniqueEvents',data);
  }
  if(environment.production){
    const uniqueFbEvents = ['sign_up','webapp_profile_completed','webapp_order_success'];
    const index = uniqueFbEvents.indexOf(eventname);
    if(index >=0){
        const localIndex = (fbLocalData.events.length > 0) ? fbLocalData.events?.indexOf(eventname) : -1;
        if(localIndex == -1){
          fbLocalData.events.push(eventname);
          this.localservice.setToLocal('fbUniqueEvents',fbLocalData);
         this.callFbEvents(this.ownProfileStoreddata?.userid, eventname);
        }
  }else {
    this.callFbEvents(this.ownProfileStoreddata?.userid, eventname);
  }
  }
}
callFbEvents(userid, eventname){
  const windowTemp: any = window;
  if(userid){ 
    windowTemp.fbq('trackCustom', eventname,{
      contents:[{
        userid: this.ownProfileStoreddata.userid,
      }]
    });
 }else{
  windowTemp.fbq('trackCustom',eventname);
 }
}
 // fb events for payment success and failure
 logPaymentCustomFbEvents(eventname,eventdetail){
  if(environment.production){
   const windowTemp: any = window;
   windowTemp.fbq('trackCustom', eventname,{
     value: 0.50,
     currency: 'INR',
     contents:[{
       userid: this.ownProfileStoreddata?.userid,
       price:eventdetail
     }]
   });
 }
 }


  
  
  oneWeekFreePlan(ownprofiledata){
    //0ne week free plan code to show model in browse............

    // let forSuccessOneWeekModel  = this.sharedservice.forOneWeekSuccessModel;
    // let forShowOneWeekModel = this.sharedservice.forOneWeekShowModel;
   // let isModelShow = this.localservice.getFromLocal('NotShowAgain');
    let isModelSuccessShow = this.localservice.getFromLocal('NotShowSuccessModel');
    var currentDate = new Date().getTime();
   // console.log(currentDate);
    
    let oneWeekPlanSuccessKey = ownprofiledata.hasOwnProperty('oneweekfreeplan');
   // let oneweekPlanScreeningKey = ownprofiledata.hasOwnProperty('oneweekscreeningwindow');
   // console.log(oneweekPlanScreeningKey);
    

    // let isquickPlan = ownprofiledata.plansNpacks.quick;
    //let isExpressPlan = ownprofiledata.plansNpacks.express;
   // let isAssistplan = ownprofiledata.plansNpacks.assisted;
    let getplantimestamp = ownprofiledata.plansNpacks.plantimestamp;

    
      if(oneWeekPlanSuccessKey && this.ownProfileStoreddata.oneweekfreeplan !=null){
        //console.log("if condition enter");
        let oneweekplanvalue = ownprofiledata.oneweekfreeplan;
        if(oneweekplanvalue > currentDate && oneweekplanvalue === getplantimestamp){
         if(!isModelSuccessShow && this.forOneWeekSuccessModel === false){
       // console.log("success plan dailog");
         return "successShowModel"
         }
        }
      } 
    //   else {

    //    if(oneweekPlanScreeningKey && ownprofiledata.oneweekscreeningwindow === true){

    //       if(isAssistplan === false && isExpressPlan === false && isquickPlan === false){

    //         if(this.ownProfileStoreddata.videodata.length <= 0 && !isModelShow){

    //             return "oneweekfreemodel"
    //         } else if(ownprofiledata.videodata.length > 0 && ownprofiledata.videodata[0].declined && !isModelShow && this.forOneWeekShowModel === false){
    //           return "oneweekfreemodel"
    //         }
    //       }
    //   } 
    // }
  

}



// goToNextHighlighter(section){
//   $('html, body').animate({
//     scrollTop: $('#' + section).offset().top - 105
//   }, 1000);
// }

// One week free banner plan in explore page .

oneWeekFreeBannerPlan(ownprofiledata){


  var currentDate = new Date().getTime(); 
  let oneWeekPlanSuccessKey = ownprofiledata.hasOwnProperty('oneweekfreeplan');

  let getplantimestamp = ownprofiledata.plansNpacks.plantimestamp;

  
    if(oneWeekPlanSuccessKey && this.ownProfileStoreddata.oneweekfreeplan !=null){
      //console.log("if condition enter");
      let oneweekplanvalue = ownprofiledata.oneweekfreeplan;
      if(oneweekplanvalue > currentDate && oneweekplanvalue === getplantimestamp){
  
       return "showOneWeekBanner"
       
      }
    } else {
      return null;
    }
}

// Add timer with countdown .............

setFreeTimerData(remainTimestamps){
  let remainingdays : any ={};
  // this.planCountDownTimer = setInterval(function() {
     // console.log("for set time interval");
    
     var countDownDate = remainTimestamps;
     var now = new Date().getTime();
     var timeleft = countDownDate - now;
 
     // Calculating the days, hours, minutes and seconds left
     remainingdays.day = Math.floor(timeleft / (1000 * 60 * 60 * 24));
     remainingdays.hour = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
     remainingdays.minute = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
     remainingdays.second = Math.floor((timeleft % (1000 * 60)) / 1000);
 

     return remainingdays;




     // Result is output to the specific element
    //  document.getElementById("days").innerHTML = this.days 
    //  document.getElementById("hours").innerHTML = this.hours 
    //  document.getElementById("mins").innerHTML = this.minutes
    //  document.getElementById("secs").innerHTML = this.seconds 


 
       // Display the message when countdown is over
    //    if (timeleft < 0) {
    //      clearInterval(this.planCountDownTimer);
    //      document.getElementById("days").innerHTML = ""
    //      document.getElementById("hours").innerHTML = "" 
    //      document.getElementById("mins").innerHTML = ""
    //      document.getElementById("secs").innerHTML = ""

    //  }
   
    //  }, 1000);
    
   }

  //  clearInterval(){
  //   clearInterval(this.planCountDownTimer);
  //  }










  // showOneWeekPlanBanner(ownprofiledata){
  //   let oneweekPlanScreeningKey = ownprofiledata.hasOwnProperty('oneweekscreeningwindow');
  //   if(oneweekPlanScreeningKey && ownprofiledata.oneweekscreeningwindow === true){
  //    // console.log("properties have");
  //      if(ownprofiledata.plansNpacks.assisted === false && ownprofiledata.plansNpacks.express === false && ownprofiledata.plansNpacks.quick === false){
  //      //  console.log("no plan have");
  //        if(ownprofiledata.videodata.length <= 0 ){
  //         // console.log("no video data");
  //            return true;
  //        } else if(ownprofiledata.videodata.length > 0 && ownprofiledata.videodata[0].declined){

  //          return true;
  //        }
  //      }
  //  } 
  // }

   // show one week free plan banner in screening

  //  showOneWeekPlanBannerScreening(ownprofiledata){
  //   let oneweekPlanOnboardKey = ownprofiledata.hasOwnProperty('oneweekfreeonboard');
  //   if(oneweekPlanOnboardKey && ownprofiledata.oneweekfreeonboard === true){
  //    // console.log("properties have");
  //        if(ownprofiledata.videodata.length <= 0 ){
  //         // console.log("no video data");
  //            return true;
  //        } else if(ownprofiledata.videodata.length > 0 && ownprofiledata.videodata[0].declined){

  //          return true;
  //        }
       
  //  } 
  // }





  getInternetConnectionStatus() {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.checkInternetSpeed();
  }

  checkInternetSpeed() {
    const connection = navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'];
    if (connection) {
      connection.addEventListener('change', () => this.evaluateConnection(connection));
      // this.evaluateConnection(connection);
    }
  }

  evaluateConnection(connection: any) {
    const speed = connection.downlink;
    const effectiveType = connection.effectiveType;
    if (speed < 1 || effectiveType === '2g' || effectiveType === '3g') {
      const snackBar = this.snackbar.open('Your internet connection is slow.', 'OKAY', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }
  }

  
  clearlocalstorageData(){
    const requesturl = this.localservice.getFromLocal('RequestUrl');
    const contactusDetails = this.localservice.getFromLocal('contactUsDetails');
      localStorage.clear();
      if(requesturl){
        this.localservice.setToLocal('RequestUrl',requesturl);
      }
      if(contactusDetails){
        this.localservice.setToLocal('contactUsDetails',contactusDetails);
      }
  }
}
