import { Injectable } from '@angular/core';
import { CanLoad, Router, CanActivate, CanDeactivate, CanActivateChild, Route, ActivatedRoute, NavigationStart } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { SharedService } from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements CanLoad {

    constructor(private localservice: LocalStorageService, private router: Router, private sharedservice: SharedService) { }

    canLoad(route: Route) {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata) {
                if (localdata.issignuppending) {
                    //  console.log('authservice onboarding');
                    this.router.navigate(['/register']);
                    return false;
                } else {
                    // console.log('authservice postlogin');
                    return true;
                }
            } else {
                this.router.navigate(['/login']);
                return false;
            }
        } catch (err) {
            // console.log('error in fetching user local data ' + err);
            return false;
        }
    }

    isRegisteredUser() {
        const localToeknObj = this.localservice.getFromLocal('user');
        try {
            if (localToeknObj.issignuppending) {
                return false;
            } else {
                return true;
            }
        } catch (err) {
            // console.log('error in getting local data ' + err);
        }
    }
}


@Injectable({
    providedIn: 'root'
})

export class CreateProfileService implements CanLoad {
    constructor(private router: Router, private localservice: LocalStorageService, private sharedservice: SharedService) { }
    canLoad(route: Route) {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata) {
                if (localdata.issignuppending) {
                    //  console.log('create profile service onboarding');
                    return true;
                } else {
                    //  console.log('create profile service postloging');
                    this.router.navigate(['/user']);
                    return false;
                }
            } else {
                this.router.navigate(['/login']);
                return false;
            }
        } catch (err) {
            // console.log('error in fetching user local data ' + err);
            return false;
        }
    }
}


@Injectable({
    providedIn: 'root'
})

export class StaticModuleService implements CanLoad {
    constructor(private router: Router, private localservice: LocalStorageService, private sharedservice: SharedService) { }
    canLoad(route: Route) {
        try {
            const localdata = this.localservice.getFromLocal('user');
            //  console.log(localdata);
            if (window.location.pathname === '/assisted-connect-verify-mobile-number' || window.location.pathname === '/assisted-connect' || window.location.pathname === '/assisted-connect-set-account-password') {
                return true;
            } else if (localdata) {
                if( window.location.pathname === '/logout'){
                    return true; 
                }
                if (window.location.pathname === '/verify') {
                    try {
                        const verifyUrl = window.location.search.substr(1).split('&');
                        const verifyId = verifyUrl[0].split('id=')[1];
                        if (localdata.issignuppending) {
                            this.router.navigate(['/register/verify'], { queryParams: { id: verifyId, utm_source: (verifyUrl[1].split('utm_source=')[1]).toString(), utm_medium: (verifyUrl[2].split('utm_medium=')[1]).toString(), utm_campaign: (verifyUrl[3].split('utm_campaign=')[1]).toString() } });
                            return false;
                        } else {
                            this.router.navigate(['/user/verify'], { queryParams: { id: verifyId, utm_source: (verifyUrl[1].split('utm_source=')[1]).toString(), utm_medium: (verifyUrl[2].split('utm_medium=')[1]).toString(), utm_campaign: (verifyUrl[3].split('utm_campaign=')[1]).toString() } });
                            return false;
                        }
                    } catch (err) {
                        // console.log('error in fetching  data ' + err);
                    }
                } else if(window.location.pathname === '/contact-us'){
                    if (localdata.issignuppending) {
                        this.sharedservice.comingForContactUs = true;
                        const temproute = this.sharedservice.getRouteForRegister(localdata.currentquestionnumber);
                        this.router.navigate([temproute]);
                        return false;
                    }
                    else {
                        let temproute = '/user/browse';
                        if (localdata.showscreeningpage) {
                            temproute = '/user/profile-screening/pending';
                        }
                        this.sharedservice.comingForContactUs = true;
                        this.router.navigate([temproute]);
                        return false;
                    }
                }
                else {
                    if (localdata.issignuppending) {
                        this.router.navigate(['/register']);
                        return false;
                    } else {
                        //  console.log('postlogin service');
                        this.router.navigate(['/user']);
                        return false;
                    }
                }
       
            } else {
                return true;
            }
        } catch (err) {
            // console.log('error in fetching user local data ' + err);
            return false;
        }
    }
}


@Injectable()

export class GuardForScreeningPage implements CanActivate {
    constructor(private router: Router, private localservice: LocalStorageService, private sharedservice: SharedService) { }
    canActivate() {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata.showscreeningpage) {
                return true;
            } else {
                this.router.navigate(['/user/browse']);
                return false;
            }
            //  return true;
        } catch (err) {
            return false;
        }
    }
}

@Injectable()

export class GuardForNonScreeningPage implements CanActivate {
    constructor(private router: Router, private localservice: LocalStorageService, private sharedservice: SharedService) { }
    canActivate() {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata.showscreeningpage) {
                this.router.navigate(['/user/profile-screening/pending']);
                return false;
            } else {
                return true;
            }
        } catch (err) {
            return false;
        }
    }
}

@Injectable()

export class GuardForOtpVerifyPage implements CanActivate {
    constructor(private router: Router, private localservice: LocalStorageService, private sharedservice: SharedService) { }
    canActivate() {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata && localdata.assistedSignUp) {
                return true;
            } else {
                this.router.navigate(['/assisted-connect']);
                return false;
            }
        } catch (err) {
            // console.log(err);
            return false;
        }
    }
}